import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { StorageLibService } from '../../storage/storage-lib.service';
import { FirebaseService } from '../firebase.service';
import { UserLibService } from '../../user/user-lib.service';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

@Component({
  selector: 'app-firebase',
  templateUrl: './firebase.component.html',
  styleUrls: ['./firebase.component.sass']
})
export class FirebaseComponent implements OnInit {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { callback?: any, service?: any },
    private bottomSheetRef: MatBottomSheetRef<FirebaseComponent>,
    private storageLib: StorageLibService,
    private angularFireMessaging: AngularFireMessaging,
    private userLib: UserLibService
  ) { }

  ngOnInit(): void {
  }

  acceptFCM() {
    this.angularFireMessaging.requestPermission.toPromise().then( v => {
      this.storageLib.set('fcm', 'y');      
      this.bottomSheetRef.dismiss();
    })
  }
  refuseFCM() {

  }
  get customerName() {
    return this.userLib.Data.token?.getCustomer().getName() || 'Light-PWA';
  }
  confirm(allow: boolean) {
    if (allow) {
      this.acceptFCM();
    } else {
      this.bottomSheetRef.dismiss();
    }
  }
}
